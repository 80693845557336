import { RadioGroupProps as AntRadioGroupProps, CheckboxOptionType, Radio } from 'antd';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import radioInputStyles from './radioInput.module.scss';

interface RadioGroupProps extends AntRadioGroupProps {
  formRegister: UseFormRegisterReturn,
  formControl: any;
  options: CheckboxOptionType[];
  className?: string;
  radioType?: typeof Radio | typeof Radio.Button;
  label?: string,
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  formRegister, formControl, options, className = '', radioType = Radio, label = null,
}) => {
  // for consistency callers can pass in radioType in camelCase, but React requires elements in PascalCase
  const RadioType = radioType;

  return (
    <div className={classNames(radioInputStyles.radioGroupContainer, 'otmow-radio-group', className)}>
      {label && (<span className={radioInputStyles.label}>{label}</span>)}
      <Controller
        name={formRegister.name}
        control={formControl as Control}
        render={({ field }) => (
          <Radio.Group {...field} className={classNames(radioInputStyles.radioGroup)}>
            {options.map((option, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <RadioType value={option.value} key={index}>
                {option.label}
              </RadioType>
            ))}
          </Radio.Group>
        )}
      />
    </div>
  );
};

interface RadioYNProps extends AntRadioGroupProps {
  label?: string;
  formRegister: UseFormRegisterReturn;
  formControl: any;
}

const RadioYN: React.FC<RadioYNProps> = ({
  label = undefined, formRegister, formControl, ...props
}) => {
  const { t } = i18next;
  const options: CheckboxOptionType[] = [
    { label: t('Yes'), value: true },
    { label: t('No'), value: false },
  ];
  return (
    <RadioGroup
      label={label}
      formRegister={formRegister}
      formControl={formControl}
      {...props}
      options={options}
    />
  );
};

export { Radio, RadioGroup, RadioYN };
